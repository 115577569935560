
export default {
	name: "IconLoader",
	props: {},
	data: function () {
		return {};
	},
	created() {},

	computed: {},
	methods: {},
};
