
import { mapActions } from "vuex";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import Alertize from "@/components/Alertize.vue";
// @ts-ignore
import Logo from "@/components/Admin/AppBar/Logo.vue";
// @ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import AppBarNotification from "@/components/Admin/AppBar/Notifications/index.vue";
// @ts-ignore
import NavigationHorizontal from "@/components/Admin/NavigationHorizontal/index.vue";
// @ts-ignore
import SelectorLanguage from "@/components/Admin/AppBar/SelectorLanguage/index.vue";
// @ts-ignore
import SelectorSettings from "@/components/Admin/AppBar/Settings/index.vue";
// @ts-ignore
import UserName from "@/components/Admin/AppBar/UserName/index.vue";
// @ts-ignore
import MenuAccount from "@/components/Admin/AppBar/MenuAccount/index.vue";
// @ts-ignore
import GPT from "@/components/Admin/AppBar/GPT/index.vue";

const ACCOUNT_TOPSORT = "topsort";

export default {
	name: "AppBar",
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
	},

	data: function () {
		return {
			search: "",
			currentItem: "",
			selectedItem: null,
			success: {
				type: MessageTypes.SUCCESS,
				title: i18n.t("title-success"),
				message: i18n.t("account-success"),
				btn_text: i18n.t("continue"),
				to: "",
				show: true,
			} as Notification,
		};
	},

	components: {
		Alertize,
		Logo,
		IconBase,
		CardTextField,
		AppBarNotification,
		NavigationHorizontal,
		SelectorLanguage,
		SelectorSettings,
		UserName,
		MenuAccount,
		GPT
	},

	async created() {
		await this.handlerSelectedTab(this.getRoutePath);
	},

	async mounted() {
		this.$nextTick(async function () {
			const profile = this.$store.state.profile.profile;
			if (!profile) {
				//await this.fetchProfile();
			}
		});
	},

	computed: {
		getRoutePath() {
			return this.$route.path;
		},

		getProfile() {
			return this.$store.state.profile.profile;
		},

		getAccount() {
			return this.$store.state.profile.account;
		},

		getUrlHelp() {
			return this.getProfile.help_url;
		},

		getItems() {
			return this.items;
		},

		isAccountTopsort(){
			return !(this.getAccount.account_type == ACCOUNT_TOPSORT);
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("breadcrumbsM", ["clearHistoryData"]),
		...mapActions({
			fetchProfile: "profile/fetchProfile",
		}),

		async onResize() {
			this.$refs.tabs && this.$refs.tabs.onResize();
		},

		setMenu() {
			this.$emit("close");
		},
		async handlerSelectedTab(clickeTab: any) {
			await this.$nextTick();
			this.currentItem = clickeTab;
			await this.onResize();
		},
		async updateTabs(){
			await this.handlerSelectedTab(this.getRoutePath);
		}
	},
	watch: {
		async currentItem() {
			await this.onResize();
		},
		async getRoutePath(val, old){
			await this.updateTabs();
		}
	},
};
