
export default {
	name: "Logo",
	props: {
		logo: {
			type: String,
			//@ts-ignore require('@/assets/logos/adsmovil_logo.png')
			default: require("@/assets/logos/adsmovil_logo.png"),
		},
	},
	data: function () {
		return {};
	},
	components: {},
	created() {},
	async mounted() {
		this.$nextTick(async function () {});
	},
	computed: {},
	methods: {},
};
