import Vue from "vue";
import { mapGetters } from "vuex";
import IconLoader from "@/components/Commons/Loaders/Loading/icon.vue";

export default Vue.extend({
	name: "CustomLoading",
	props: {
		absolute: {
			type: Boolean,
			default: false,
		},
		opacity: {
			type: Number,
			default: 0.3,
		},
		z_index: {
			type: Number,
			default: 9999,
		},
	},
	components: { IconLoader },
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {
		...mapGetters("loading", ["isLoading", "getLoadingMessage"]),
		overlay: {
			set() {},
			get() {
				return this.isLoading;
			},
		},
	},
	methods: {},
	watch: {},
});
