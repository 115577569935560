
import Vue from "vue";
import { getVersionFromPackageJson } from "@/scripts/package/get";

export default Vue.extend({
	name: "GitVersion",
	props: {},
	components: {},
	data: () => ({
		version: "",
	}),
	created() {
		this.$nextTick(async () => {
			this.version = await getVersionFromPackageJson();
		});
	},
	mounted() {},
	computed: {},
	methods: {},
});
