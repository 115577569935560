import Vue from "vue";


export default Vue.extend({
	name: "GPT",
	props: {},
	components: {},
	data: () => ({
    show: false
  }),
	created() {},
	mounted() {},
	computed: {
  },
	methods: {
    handleClick() {
      this.show = !this.show;
    }
  },
	watch: {},
});
