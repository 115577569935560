import Vue from 'vue';
import ImageSelector from "@/components/Content/Uploader/ImageSelector.vue";

export default Vue.extend({
    name:"Photographic",
    props: {
        avatar:{
            type: String,
            required:true
        },
        edit:{
            type:Boolean,
            default: false
        },
        heightModal:{
            type:Number,
            default: 250
        },
        loading:{
            type:Boolean,
            default: false
        },
    },
	components: {
        ImageSelector
    },
    data: () => ({
        avatar_File: "",
        file: null
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            this.avatar_File = this.avatar;
        });
    },
    computed:{
        isEdit(){
            return this.edit;
        }
    },
    methods:{
        handleClose(){
            this.$emit("handle-close-modal");
        },
        handleSaveImage(){
            this.$emit("handle-save-image");
        },
        handleChangeImage(){
            this.$emit("handle-change-image");
        },
        handleUpdateUpload(event: {
			avatar_file: string | null;
			upload_file: File | null;
		}){
            this.file = event.upload_file;
            this.$emit("handle-update-image", event);
        }
    },
    watch:{
        "avatar"(){
            this.avatar_File = this.avatar;
        }
    }
})