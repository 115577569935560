import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { isNull } from "lodash";

const interval: number = 300000;

export default Vue.extend({
	name: "AppNotification",
	props: {},
	components: { IconBase },
	data: () => ({
		timer: null,
	}),
	created() {
		this.$nextTick(async () => {
			this.fetchMessages();
		});
	},
	async mounted() {
		this.setupInterval();
	},
	beforeDestroy() {
		clearInterval(this.interval);
	},
	computed: {
		...mapGetters("notification", [
			"isLoading",
			"getResultPaginated",
			"getPaginated",
			"getOptions",
		]),
		...mapGetters("profile", ["account"]),
		...mapGetters("internationalization", ["getLanguage"]),
		getMessages() {
			return this.getResultPaginated?.data || [];
		},
		hasMessages() {
			return this.getResultPaginated?.data?.length > 0;
		},
		countMessages() {
			return this.getResultPaginated?.total || 0;
		},
	},

	methods: {
		...mapActions("notification", [
			"fetchNotifications",
			"incrementPage",
			"sendDismissNotification",
			"resetNotification"
		]),

		verifiPathFile(file_Path: string){
			return !isNull(file_Path)
		},

		async setupInterval() {
			this.timer = setInterval(() => {
				this.fetchMessages();
			}, interval);
		},

		getCreatedAt(created_at: string) {
			let date = new Date(Date.parse(created_at));
			return date.toLocaleString();
		},
		async fetchMessages(enableLoading: Boolean = false) {
			await this.fetchNotifications({
				paginated: this.getPaginated,
				options: this.getOptions,
				enableLoading: enableLoading,
			});
		},
		async refreshFetchMessages() {
			await this.fetchNotifications({
				paginated: this.getPaginated,
				options: this.getOptions,
				isRefresh: true,
				enableLoading: true,
			});
		},
		async handleViewMore() {
			await this.incrementPage();
			await this.fetchMessages(true);
		},
		async dismissNotification(id: any) {
			//console.log(`${this.$options.name}::dismissNotification`, id);
			await this.sendDismissNotification([id]);
		},
		async dismissAllNotifications() {
			const ids: string[] = this.getMessages?.map(
				(m: { id: any }) => m.id
			);
			//console.log(`${this.$options.name}::dismissAllNotifications`, ids);
			await this.sendDismissNotification(ids);
		},
		async resetNotifications(){
			await this.resetNotification();
		},

		async changeLanguage(lang){
			let l = lang == "pt" ? "pr" : lang;
			this.getMessages.forEach((m) => {
				let { title, description } = m[l];
				m.title = title;
				m.description = description;
			});
		}
	},

	watch: {
		"account"(val, old){
			this.resetNotifications();
		},
		async getLanguage(lang) {
			await this.changeLanguage(lang);
		}
	},
});
