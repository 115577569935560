import Vue from "vue";

export default Vue.extend({
	name: "SnackMessage",
	model: {
		prop: "message",
		event: "input",
	},
	props: {
		message: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "secondary",
		},
		btnColor: {
			type: String,
			default: "",
		},
		timeout: {
			type: Number,
			default: 5000,
		},
		width: {
			type: Number,
			default: 500,
		},
		vertical: {
			type: Boolean,
			default: false,
		},
		outlined: {
			type: Boolean,
			default: false,
		},
		app: {
			type: Boolean,
			default: true,
		},
		absolute: {
			type: Boolean,
			default: false,
		},
		top: {
			type: Boolean,
			default: false,
		},
		bottom: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: () => ({
		open: false,
	}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {
		modelOpen: {
			get() {
				return !!this.message;
			},
			set(val) {
				if (!val) {
					this.$emit("input", "");
				}
			},
		},
		modelMessage: {
			get() {
				return this.message;
			},
			set(val) {
				this.$emit("input", val);
				this.open = !!val;
			},
		},
	},
	methods: {
		handleClick() {
			this.$emit("close");
		},
	},
	watch: {
		message(val) {
			this.open = !!val;
		},
	},
});
