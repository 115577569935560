import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { TypeLoading } from "@/interfaces/loading";
import GitVersion from "@/components/Commons/gitVersion.vue";

export default Vue.extend({
	name: "SelectorSettings",
	model: {},
	props: {},
	components: { IconBase, SnackMessage, GitVersion },
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	beforeDestroy() {},
	async mounted() {},
	computed: {
		...mapGetters("profile", ["profile", "getCurrentLanguage"]),
		getProfile() {
			return this.$store.state.profile.profile;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions({
			signOff: "auth/signOff",
		}),
		changePassword() {
			this.$router.push({ name: "changePassword" });
		},
		gotToRoute(name: string) {
			this.$router.push({ name: name });
		},
		async dispatchLogout() {
			await this.setLoadingData(TypeLoading.loading);
			await this.signOff();
			this.setLoadingData();
		},
	},
	watch: {},
});
