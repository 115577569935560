import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { isEmpty } from "lodash";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { ComboListOptions } from "@/utils/resolveObjectArray";
import { UserLanguage } from "@/interfaces/internalization";

export default Vue.extend({
	name: "SelectorLanguage",
	model: {},
	props: {},
	components: { IconBase, SnackMessage },
	data: () => ({
		message: "",
		snack_color: "",
	}),
	created() {
		this.$nextTick(async () => {
			await this.fetchLanguages();
			await this.changeLanguage(this.getProfile.language);
		});
	},
	beforeDestroy() {},
	async mounted() {},
	computed: {
		...mapGetters("profile", ["profile", "getCurrentLanguage"]),
		...mapGetters("internationalization", [
			"getLanguages",
			"getLanguage",
			"getLanguageData",
		]),
		getProfile() {
			return this.$store.state.profile.profile;
		},
		hasLanguage() {
			return !isEmpty(this.getLanguages);
		},
		getLanguagesFiltered() {
			const langs: ComboListOptions[] = this.getLanguages;
			return langs.filter((l) => l.id !== this.getLanguage);
		},
		compGetLanguageData() {
			return this.getLanguageData;
		},
	},
	methods: {
		...mapActions("internationalization", [
			"fetchLanguages",
			"setLanguage",
			"setSnack",
		]),

		...mapActions("profile", ["setProfileLanguage"]),

		isCurrentLanguage(lang: string) {
			return this.getLanguage === lang;
		},

		/**
		 * Update Language (Api)
		 * @param lang
		 */
		async updateLang(lang: string) {
			const params: UserLanguage = {
				id: this.getProfile.id,
				language: lang,
			};
			this.setProfileLanguage(lang);
			await this.setLanguage(params);
		},

		/**
		 * Update Language (App)
		 * @param lang
		 */
		async changeLanguage(language: string) {
			if(language === this.getLanguage) return;
			await this.updateLang(language);
			const langData: ComboListOptions = this.getLanguageData;
			const lang = langData.value;
			if (!lang) return;
			await this.setSnack(lang);
		},
	},
	watch: {},
});
