export interface Agent {
	isChrome: Boolean;
	isFirefox: Boolean;
}

export enum AgentBrowser {
	FIREFOX = "firefox",
	CHROME = "chrome",
}

export function isUserAgentByKey(key: string) {
	return navigator.userAgent.toLowerCase().indexOf(key) > -1;
}

export default class UserAgent implements Agent {
	isChrome: Boolean = false;
	isFirefox: Boolean = false;

	constructor() {
		this.isFirefox = isUserAgentByKey(AgentBrowser.FIREFOX);
		this.isChrome = isUserAgentByKey(AgentBrowser.CHROME);
	}

	getAppClass() {
		return {
			[AgentBrowser.CHROME]: this.isChrome,
			[AgentBrowser.FIREFOX]: this.isFirefox,
		};
	}
}
