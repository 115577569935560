import { isEmpty } from "lodash";
import Vue from "vue";

export default Vue.extend({
	name: "NavigationHorizontal",
	props: {
		items: {
			type: Array,
			default: function () {
				return [] as any[];
			},
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		getRoutePath() {
			return this.$route.path;
		},
	},
	methods: {
		hasChildrenCurrentPath(children: any[]) {
			if (isEmpty(children)) return false;
			return children.some((c) => {
				if (c.children) return this.hasChildrenCurrentPath(c.children);
				return c.href === this.getRoutePath;
			});
		},
		isActive(href: string) {
			return href === this.getRoutePath;
		},
		setMenu() {
			this.$emit("close");
		},
	},
	watch: {},
});
