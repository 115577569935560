/**
 * Leer la version desde package.json
 * @returns
 */
export async function getVersionFromPackageJson(): Promise<string | null> {
	try {
		const packageJson = require("../../../package.json");
		return packageJson.version;
	} catch (error) {
		console.error("Error al leer el archivo package.json:", error);
		return null;
	}
}
